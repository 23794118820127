// eslint-disable-next-line no-redeclare
/* global blueConicClient, acquireIO */
import { dom } from 'core-utils';

const __ = {},
	exports = {
		__: __,
	};
// private vars
__.poaHandler = function (event) {
	event.preventDefault();

	try {
		if (acquireIO) {
			acquireIO.max()();
		}
	} catch (e) {
		console.warn(e);
	}
};

__.cookieHandler = function (event) {
	event.preventDefault();

	try {
		if (blueConicClient) {
			blueConicClient.event.publish(
				'optin_optout_openPermissionLevelSelector',
			);
		}
	} catch (e) {
		console.warn(e);
	}
};

__.addEvents = function () {
	__.domEventDelegate.on('click', '.nm-j-poa', __.poaHandler);
	__.domEventDelegate.on('click', '.nm-j-ext-cookie', __.cookieHandler);
};

__.initialize = function () {
	__.addEvents();
};

exports.initialize = function () {
	__.domEventDelegate = dom.getEventDelegate('body');
	__.initialize();
};

exports.initialize();

export { exports as poa };
