import { appEvents } from 'core-application';
import { patternMatching } from './pattern-matching/pattern-matching';

class BounceLink {
	static get BOUNCE_CLASS() {
		return '.audi-js-bounce-link';
	}

	/**
	 * initialize class
	 * @param {Object} eventBus - Initialized on global bundle
	 * @returns {void} returns nothing
	 */
	initialize(eventBus) {
		const _processURL = this._processURL.bind(this);

		eventBus.on(appEvents.CONFIG_UPDATE, _processURL);
		eventBus.on(appEvents.LAYER_LOADED, _processURL);
		eventBus.on(appEvents.CONFIG_START, _processURL);
		eventBus.on(appEvents.PAGE_LOADED, _processURL);
	}

	/**
	 * Rewrites the HREF of all anchors on the site. If pattern cannot be matched, keep the original URL.
	 *
	 * @returns {Promise} resolves with void
	 */
	async _processURL() {
		return Promise.all(
			[...document.querySelectorAll(BounceLink.BOUNCE_CLASS)].map(
				async (anchor) => {
					const originalURL = anchor.getAttribute('href');
					const pattern = anchor.getAttribute('data-link-pattern');
					if (originalURL && pattern) {
						const newURL = await patternMatching.getReplacedUrl(
							pattern,
							originalURL,
						);
						anchor.setAttribute('href', newURL);
					}
				},
			),
		);
	}
}

const bounceLink = new BounceLink();
export { bounceLink, BounceLink };
