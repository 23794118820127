import { appEvents } from 'core-application';
import { dom } from 'core-utils';
import { scroll } from './scroll';

const __ = {},
	exports = {
		__: __,
	};

__.registeredModules = {};
__.GAP = 70;
__.MAX_INTERVAL_COUNT = 10;
__.BETWEEN_INTERVAL = 200;

/**
 * register - public function other modules can use to register for entry scroll
 * @param {string} key_ - scroll parameter
 * @param {string} dataAttribute_ - data attribute to finde elements position
 * @returns {void} returns nothing
 */
exports.register = function (key_, dataAttribute_) {
	__.registeredModules[key_] = dataAttribute_;
};

/**
 * handlePageLoaded
 * get scroll param and check if module is in registered modules.
 * Starts interval check if element is visible and scrolls.
 * @returns {void} returns nothing
 */
__.handlePageLoaded = function () {
	const moduleToScroll = __.getScrollParamFromHash();
	let module;

	for (module in __.registeredModules) {
		// eslint-disable-next-line no-prototype-builtins
		if (__.registeredModules.hasOwnProperty(module)) {
			if (moduleToScroll === module) {
				__.startInterval(module);
			}
		}
	}
};

/**
 * @param {string} module - module to scroll to
 * @return {void} returns nothing
 */
__.startInterval = function (module) {
	let interval,
		elementOnPage,
		counter = 0,
		elementSelector =
			'[data-module="' + __.registeredModules[module] + '"]';

	interval = setInterval(function () {
		elementOnPage = dom.getElement(elementSelector);
		if (dom.isElement(elementOnPage) && !dom.isHidden(elementOnPage)) {
			clearInterval(interval);
			scroll.scrollToElementWithDistance(elementSelector, __.GAP);
		}
		if (counter === __.MAX_INTERVAL_COUNT) {
			clearInterval(interval);
		}
		counter++;
	}, __.BETWEEN_INTERVAL);
};

/**
 * getScrollParamFromHash
 * finds scroll elemet from location hash
 * @return {string} - module to scroll to
 */
__.getScrollParamFromHash = function () {
	let hash = location.hash,
		scrollModule;
	hash = hash.match(/scroll=[a-zA-Z0-9]*/);
	if (hash) {
		scrollModule = hash[0].split('=')[1] || null;
	}
	return scrollModule || null;
};

/**
 * addEvents
 * Adds events for entry scroll
 * @returns {void} returns nothing
 */
__.addEvents = function () {
	__.eventBus.on(appEvents.LAYER_CLOSED, __.handlePageLoaded);
};

exports.initialize = function (eventEmitter) {
	__.eventBus = eventEmitter;
	__.addEvents();
};

export { exports as entryScroll };
