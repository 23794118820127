/* eslint-disable max-len */
const exports = `
	<li class="nm-iph-item">
		<a href="{{=it.dealerURL}}" target="_blank" class="nm-iph-item nm-iph-name nm-el-pg nm-el-pg-04 nm-at-pg-b" title="{{=it.dealerName}}">{{=it.dealerName}}</a>
		<a x-cq-linkchecker="skip" href="#" class="nm-icon-cancel"></a>
	</li>
	<li class="nm-iph-item">
		<span class="nm-iph-phone nm-el-pg nm-el-pg-04">{{=it.dealerPhoneLabel}} {{=it.dealerPhone}}</span>
	</li>
`;
/* eslint-enable max-len */

export { exports as iphCartTemplate };
