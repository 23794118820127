/* global SETUPS */
import { dom } from 'core-utils';
import { menuFlyOut } from './menu-fly-out';

const FLYOUT_SWITCH_SELECTOR = '.nm-navigation-carline-main-item > a',
	FLYOUT_SELECTOR = '.nm-navigation-header-model-overlay',
	FLYOUT_CLOSE_BUTTON = '.nm-close',
	CARLINE_SELECTOR = '.nm-navigation-header-wrapped-link',
	CARLINE_CONTAINER_SELECTOR = '.nm-navigation-model-container',
	CARLINE_CONTAINER_ACTIVE_CLASS = 'nm-navigation-model-container-active',
	MODEL_SELECTOR_WRAP = '.nm-navigation-model-container > ul',
	MODEL_ACTIVELINK_SELECTOR =
		'.nm-navigation-model-container-active > ul > li.nm-header-detail-link-active',
	MODEL_LINK_SELECTOR = '.nm-navigation-model-container > ul > li > a',
	DETAIL_ACTIVE_CLASSNAME = 'nm-header-detail-link-active',
	DROPDOWN_ITEM_SELECTOR = '.nm-navigation-header-dropdown-item a',
	DEFAULT_FLYOUT_SELECTOR = '.nm-header-overlay-is-open',
	SEARCH_INPUT = '.nm-su-input',
	HOVER_TIMEOUT = 300,
	TRIANGLE_HEIGHT = 460,
	FLYOUT_TIMEOUT = 100,
	__ = {},
	exports = { __: __ };

/**
 * setdetailContentHeight
 * sets the height of the flyout container to match its detail content + bottom-margin
 * @returns {void} returns nothing
 */
__.setdetailContentHeight = function () {
	let submenu,
		detailContent,
		flyoutContainer,
		detailContentBottomOffset = 85;

	flyoutContainer = dom.getElement(FLYOUT_SELECTOR);
	submenu = dom.getElement(
		'.nm-navigation-model-subnavigation',
		flyoutContainer,
	);
	detailContent = dom.getElement(
		'.nm-navigation-model-container-active .nm-header-detail-link-active .nm-navigation-header-detail-overlay-content',
		flyoutContainer,
	);
	flyoutContainer.style.height =
		submenu.clientHeight +
		detailContent.clientHeight +
		detailContentBottomOffset +
		'px';
};

__.handleFlyoutOpen = function () {
	const flyoutContainer = dom.getElement(FLYOUT_SELECTOR);
	if (
		dom.isElement(flyoutContainer) &&
		!flyoutContainer.classList.contains('flyout-is-open')
	) {
		flyoutContainer.classList.add('flyout-is-open');

		// set height of active detail container
		__.setdetailContentHeight();
	}
};

__.closeSearchFlyout = function () {
	const searchInput = dom.getElement(
		'.nm-header-overlay-is-open ' + SEARCH_INPUT,
	);
	const flyout = dom.getElementsArray(DEFAULT_FLYOUT_SELECTOR);
	if (dom.isElement(searchInput)) {
		searchInput.blur();
	}
	flyout.forEach(function (item) {
		menuFlyOut.close(item);
	});
};

__.checkForFlyoutClose = function () {
	__.flyoutInterval = setTimeout(function () {
		const flyout = dom.getElement(FLYOUT_SELECTOR);
		if (dom.isElement(flyout)) {
			flyout.classList.remove('flyout-is-open');
		}
	}, FLYOUT_TIMEOUT);
};
__.stopFlyoutClose = function () {
	clearTimeout(__.flyoutInterval);
};

__.handleCarlineNavigationHover = function (e) {
	const item = e.target;
	if (dom.isElement(item)) {
		let ref = item.getAttribute('data-ref-id');
		let carlines = dom.getElementsArray(CARLINE_SELECTOR);
		carlines.forEach(function (carline) {
			carline.classList.remove('active');
		});
		item.classList.add('active');

		let carlineModelContainer = dom.getElementsArray(
			CARLINE_CONTAINER_SELECTOR,
		);
		carlineModelContainer.forEach(function (container) {
			container.classList.remove(CARLINE_CONTAINER_ACTIVE_CLASS);
		});
		carlineModelContainer[ref - 1].classList.add(
			CARLINE_CONTAINER_ACTIVE_CLASS,
		);

		// set height of active detail container
		__.setdetailContentHeight();
	}
};

__.handleModelNavigationMouseEnter = function (e) {
	e.preventDefault();
	e.stopImmediatePropagation();
	__.activeItem = dom.getElement(MODEL_ACTIVELINK_SELECTOR);
	__.domEventDelegate.on('mousemove', __.handleNavigationMouseMove);
};

__.handleModelNavigationMouseLeave = function () {
	__.domEventDelegate.off('mousemove', __.handleNavigationMouseMove);
};

__.handleActiveItemChange = function (newActiveItem) {
	__.activeItem.classList.remove(DETAIL_ACTIVE_CLASSNAME);
	__.activeItem = newActiveItem;
	__.activeItem.classList.add(DETAIL_ACTIVE_CLASSNAME);

	// set height of active detail container
	__.setdetailContentHeight();
};

__.handleHoverTimeout = function () {
	if (
		dom.isElement(__.activeItemNominee) &&
		!(__.activeItemNominee.parentNode === __.activeItem)
	) {
		__.handleActiveItemChange(__.activeItemNominee.parentNode);
	}
};

__.handleNavigationMouseMove = function (e) {
	// eslint-disable-line max-statements
	__.activeItemNominee = dom.closest(e.target, MODEL_LINK_SELECTOR);
	if (!__.activeItemNominee) {
		return;
	}

	let modelSelectorWrap = dom.closest(e.target, MODEL_SELECTOR_WRAP);
	let modelSelectorWrapLink = dom.getElement(
		'a',
		modelSelectorWrap.firstElementChild,
	);
	__.topBoundingRect = modelSelectorWrapLink.getBoundingClientRect();

	// limit to visible li area (the li-s currently use full horizontal space...)
	if (e.clientX < __.topBoundingRect.right) {
		__.x0 = e.clientX;
		__.y0 = e.clientY;

		if (!__.x1 || !__.y1) {
			__.x1 = __.x0;
			__.y1 = __.y0;
		}

		__.x2 = __.topBoundingRect.right;
		__.y2 = Math.max(0, __.topBoundingRect.top);
		__.x3 = __.x2;
		__.y3 = __.topBoundingRect.top + TRIANGLE_HEIGHT;

		if (__.activeItemNominee.parentNode === __.activeItem) {
			if (
				!menuFlyOut.isInsideTriangle(
					__.x0,
					__.y0,
					__.x1,
					__.y1,
					__.x2,
					__.y2,
					__.x3,
					__.y3,
				)
			) {
				__.x1 = __.x0;
				__.y1 = __.y0;
			}
		} else {
			if (__.activeItem) {
				if (
					menuFlyOut.isInsideTriangle(
						__.x0,
						__.y0,
						__.x1,
						__.y1,
						__.x2,
						__.y2,
						__.x3,
						__.y3,
					)
				) {
					clearTimeout(__.hoverTimeout);

					__.hoverTimeout = setTimeout(
						__.handleHoverTimeout,
						HOVER_TIMEOUT,
					);
				} else {
					__.handleActiveItemChange(__.activeItemNominee.parentNode);
				}
			}
		}
	}
};

__.addEvents = function addEvents() {
	__.domEventDelegate.on(
		'mouseover',
		FLYOUT_SWITCH_SELECTOR,
		__.handleFlyoutOpen,
	);
	__.domEventDelegate.on(
		'mouseout',
		FLYOUT_SWITCH_SELECTOR,
		__.checkForFlyoutClose,
	);

	__.domEventDelegate.on('mouseover', FLYOUT_SELECTOR, __.stopFlyoutClose);
	__.domEventDelegate.on('mouseout', FLYOUT_SELECTOR, __.checkForFlyoutClose);

	__.domEventDelegate.on(
		'mouseover',
		CARLINE_SELECTOR,
		__.handleCarlineNavigationHover,
	);

	__.domEventDelegate.on(
		'mouseover',
		MODEL_SELECTOR_WRAP,
		__.handleModelNavigationMouseEnter,
	);
	__.domEventDelegate.on(
		'mouseout',
		MODEL_SELECTOR_WRAP,
		__.handleModelNavigationMouseLeave,
	);

	__.domEventDelegate.on(
		'mouseover',
		DROPDOWN_ITEM_SELECTOR,
		__.closeSearchFlyout,
	);

	__.domEventDelegate.on(
		'click',
		FLYOUT_SELECTOR + ' ' + FLYOUT_CLOSE_BUTTON,
		__.closeSearchFlyout,
	);
};

__.removeEvents = function removeEvents() {
	__.domEventDelegate.off(
		'mouseover',
		FLYOUT_SWITCH_SELECTOR,
		__.handleFlyoutOpen,
	);
	__.domEventDelegate.off(
		'mouseout',
		FLYOUT_SWITCH_SELECTOR,
		__.checkForFlyoutClose,
	);

	__.domEventDelegate.off('mouseover', FLYOUT_SELECTOR, __.stopFlyoutClose);
	__.domEventDelegate.off(
		'mouseout',
		FLYOUT_SELECTOR,
		__.checkForFlyoutClose,
	);

	__.domEventDelegate.off(
		'mouseover',
		CARLINE_SELECTOR,
		__.handleCarlineNavigationHover,
	);

	__.domEventDelegate.off(
		'mouseover',
		MODEL_SELECTOR_WRAP,
		__.handleModelNavigationMouseEnter,
	);
	__.domEventDelegate.off(
		'mouseout',
		MODEL_SELECTOR_WRAP,
		__.handleModelNavigationMouseLeave,
	);

	__.domEventDelegate.off(
		'mouseover',
		DROPDOWN_ITEM_SELECTOR,
		__.closeSearchFlyout,
	);

	__.domEventDelegate.off(
		'click',
		FLYOUT_SELECTOR + ' ' + FLYOUT_CLOSE_BUTTON,
		__.closeSearchFlyout,
	);
};

__.initialize = function () {
	if (SETUPS.get('hasModelNavigationWrap') === true) {
		__.addEvents();
	}
};

exports.initialize = function () {
	__.domEventDelegate = dom.getEventDelegate('body');
	__.initialize();
};

exports.initialize();

export { exports as modelNavigationWrap };
