import { dom } from 'core-utils';

const __ = {},
	exports = {
		__: __,
	};

__.oDefaults = {
	layerSubstr: '.nm-layer-opened .nm-layer ',
	layerWrapperSelector: '.nm-layer-wrapper',
	scrollDataAttributeName: 'data-scroll',
};

/**
 * handle clicks on scroll anchors
 * @param {Event} event_ - dom click event
 * @returns {void}
 */
__.handler = function (event_) {
	event_.preventDefault();
	const clickTarget = dom.closest(
		event_.target,
		'[' + __.oDefaults.scrollDataAttributeName + ']',
	);
	const scrollToElementSelector = __.getScrollAnchorClassSelector(
		clickTarget.getAttribute(__.oDefaults.scrollDataAttributeName),
	);
	const stickyNavigation = document.querySelector(
		'.nm-nav-fixed .nm-nav-wrap',
	);
	const offset = stickyNavigation ? stickyNavigation.clientHeight : 0;

	__.detectScrollElementAndScroll(scrollToElementSelector, offset);
};

/**
 * detectScrollElementAndScroll description
 * @param {string} scrollToElementSelector_ element selector
 * @param {Number} offset_ offset to be subtracted from scroll position
 * @return {Void} returns nothing
 */
__.detectScrollElementAndScroll = function (scrollToElementSelector_, offset_) {
	let scrollElements,
		scrollElement,
		scrollToPosition,
		offset = offset_ || 0;

	// if we don't have a class and a matching element there will be nothing do to
	if (scrollToElementSelector_ && dom.isElement(scrollToElementSelector_)) {
		if (dom.selectorIsInLayerContext(scrollToElementSelector_)) {
			scrollElements = dom.getElementsArray(
				__.oDefaults.layerSubstr + scrollToElementSelector_,
			);
			scrollElement = dom.getElement(__.oDefaults.layerWrapperSelector);
		} else {
			scrollElements = dom.getElementsArray(scrollToElementSelector_);
			scrollElement = dom.getElement('body');
		}

		// remove hidden elements
		scrollElements = scrollElements.filter(function (element) {
			return !dom.isHidden(element);
		});

		// get the position of the element (ignore multiple occurences)
		scrollToPosition = __.getElementsScrollPosition(scrollElements);

		// scroll content (window or layer)
		__.scrollContent(scrollElement, scrollToPosition - offset);
	}
};

/**
 * scrollToElementWithDistance - public scroll to element function with distance parameter
 * @param {string} scrollToElementSelector_ - scrollto element selector
 * @param {Number} offset_ - distance on top of element to scroll
 * @return {void} - returns nothing
 */
exports.scrollToElementWithDistance = function (
	scrollToElementSelector_,
	offset_,
) {
	__.detectScrollElementAndScroll(scrollToElementSelector_, offset_);
};

/**
 * get className Selector from achorname
 * @param {string} anchorName_ - anchorname
 * @returns {string} selector
 */
__.getScrollAnchorClassSelector = function (anchorName_) {
	let className = anchorName_;

	// if we don't have a class, just return because there is nothing do to
	if (typeof className !== 'string' || className.length < 2) {
		className = null;
	} else {
		// add '.' to className
		className = '.' + className;
	}
	return className;
};

/**
 * we need to scroll in different elements,
 * depending of where the element to scroll to is (layer or page)
 * @param {HTMLElement} scrollElement_ - content to scroll
 * @param {number} scrollToPosition_ - position to scroll to
 * @return {void} nothing
 */
__.scrollContent = function (scrollElement_, scrollToPosition_) {
	if (dom.isElement(scrollElement_) && !isNaN(scrollToPosition_)) {
		if (scrollElement_ === document.body) {
			dom.scrollTo(scrollToPosition_, 400);
		} else {
			dom.animateElementY(scrollToPosition_, scrollElement_, 400);
		}
	}
};
/**
 * @param {array} scrollElements_ - found elements mathing the selector
 * @param {string} scrollClassName_ - initial className for this scroll anchor
 * @return {number} scrollToPosition - position to scroll to
 */
__.getElementsScrollPosition = function (scrollElements_, scrollClassName_) {
	let scrollToPosition = 0,
		scrollOffsetY = parseInt(window.scrollY || window.pageYOffset, 10);

	if (scrollElements_ && scrollElements_[0]) {
		// get the position of the element (first found element matching the selector) to scroll to
		scrollToPosition = parseInt(
			scrollElements_[0].getBoundingClientRect().top + scrollOffsetY,
			10,
		);

		// BUGFIX::ignore duplicate ".nm-top" marker and jump to Ypos=0
		if (scrollElements_.length > 1 && scrollClassName_ === 'nm-top') {
			scrollToPosition = 0;
		}
	}
	return scrollToPosition;
};

__.addEvents = function () {
	dom.getEventDelegate('body').on('click', '.nm-j-scroll', __.handler);
};

exports.initialize = function () {
	__.addEvents();
};

exports.initialize();

export { exports as scroll };
