import { state } from './state';

class InterceptDebugMode {
	constructor() {
		if (InterceptDebugMode._instance) {
			return InterceptDebugMode._instance;
		}

		InterceptDebugMode._setState();

		InterceptDebugMode._instance = this;
	}

	/**
	 * Reads window.location.href and triggers State.triggerModeChange.
	 * The "debug" mode is enabled if the query parameter debug=true is present.
	 * Otherwise it is disabled.
	 * @returns {void}
	 * @private
	 */
	static _setState() {
		if (InterceptDebugMode._urlContainsDebugMode()) {
			state.triggerModeChange('debug', 'true');
		} else {
			state.triggerModeChange('debug', 'false');
		}
	}

	/**
	 * Returns true if window.location.href contains the query parameter debug=true.
	 * Otherwise returns false.
	 * @returns {boolean} - indicates whether window.location.href contains the query parameter debug=true or not.
	 * @private
	 */
	static _urlContainsDebugMode() {
		return window.location.href.indexOf('debug=true') !== -1;
	}
}

const interceptDebugMode = new InterceptDebugMode();
export { interceptDebugMode, InterceptDebugMode };
