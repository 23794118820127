const __ = {},
	exports = {
		__: __,
	};
// private vars
const stateOfModes = {
	1920: false,
	debug: false,
	module: false,
};

/**
 * returns the state (true|false) for the given mode
 *
 * @param {string} mode - the mode, the status should be returned
 * @returns {boolean} state for the given mode
 */
exports.getStateOfModeFor = function (mode) {
	return stateOfModes[mode] || false;
};

/**
 * Triggers a change in one mode, either to 'true' or 'false', which results in setting or removing a body-class or -atribute
 *
 * @param {string} modeToTrigger one of the possible states
 * @param {string} stateToChangeTo (true|false)
 * @returns {boolean} returns true, if the change was successfull, otherwise false
 */
exports.triggerModeChange = function (modeToTrigger, stateToChangeTo) {
	let changeStateTo;

	// the parameter 'stateToChangeTo' needs to be true or false
	if (stateToChangeTo === 'true' || stateToChangeTo === true) {
		changeStateTo = true;
	} else {
		changeStateTo = false;
	}

	// the variable modeToTrigger needs to be in the list of available modes
	if (typeof stateOfModes[modeToTrigger] === 'undefined') {
		return false;
	}

	if (changeStateTo === true) {
		__.activateState(modeToTrigger);
	} else {
		__.deactivateState(modeToTrigger);
	}

	return true;
};

/**
 * activates the given mode
 *
 * @param {string} mode one of the possible modes
 * @returns {void}
 */
__.activateState = function (mode) {
	const bodyElement = __.getDomBodyElement();
	const htmlElement = __.getDomHtmlElement();

	if (mode === '1920') {
		bodyElement.setAttribute('page-type', 'widescreen');
		bodyElement.setAttribute('page-override', 'widescreen-overwrite');
	}
	stateOfModes[mode] = true;
	htmlElement.classList.add('nm-state-is-' + mode);
};

/**
 * deactivates the given modes
 *
 * @param {string} mode one of the possible modes
 * @returns {void}
 */
__.deactivateState = function (mode) {
	const bodyElement = __.getDomBodyElement();
	const htmlElement = __.getDomHtmlElement();

	if (mode === '1920') {
		bodyElement.removeAttribute('page-type', 'widescreen');
		bodyElement.removeAttribute('page-override', 'widescreen-overwrite');
	}
	stateOfModes[mode] = false;
	htmlElement.classList.remove('nm-state-is-' + mode);
};

/**
 * returns the <body> from the dom
 *
 * @returns {HTMLElement} dom-reference to <body>
 */
__.getDomBodyElement = function () {
	return document.querySelector('body');
};

/**
 * returns the <html> from the dom
 *
 * @returns {HTMLElement} dom-reference to <html>
 */
__.getDomHtmlElement = function () {
	return document.querySelector('html');
};

export { exports as state };
