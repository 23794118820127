/**
 * if an url is followed by parameter #hideHeaderFooter
 * the header and footer will be hidden
 */

import { dom } from 'core-utils';

let __ = {},
	exports = {
		__: __,
	};

__.oDefaults = {
	selectorFooter: '.nm-footer',
	selectorFootnote: '.nm-footnote-wrap',
	selectorHeader: '.nm-header',
};

/**
 * @returns {undefined}
 */
__.initialize = function () {
	if (__.isHideHeaderFooter()) {
		__.persistHideHeaderFooter();
		__.removeHeaderFooter();
	} else if (__.isHideHeaderFooterPersistent()) {
		__.removeHeaderFooter();
	}
};

/**
 * persisting the attribute hide header footer in the session
 * @returns {undefined}
 */
__.persistHideHeaderFooter = function () {
	let storage = __.getSessionStorage();
	if (storage) {
		storage.setItem('hideHeaderFooter', true);
	}
};

/**
 * @returns {boolean} whether the url parameter hideHeaderFooter is set
 */
__.isHideHeaderFooter = function () {
	return (
		window.location.hash &&
		window.location.hash.indexOf('hideHeaderFooter') > -1
	);
};

/**
 * @returns {boolean} whether the parameter hideHeaderFooter is set
 */
__.isHideHeaderFooterPersistent = function () {
	let storage = __.getSessionStorage(),
		isHideHeaderFooter = false;
	if (storage) {
		isHideHeaderFooter = storage.getItem('hideHeaderFooter');
	}
	return isHideHeaderFooter;
};

/**
 * @returns {Object} the session storage
 */
__.getSessionStorage = function () {
	return window.sessionStorage || null;
};

/**
 * hides the header and footer
 * @returns {undefined}
 */
__.removeHeaderFooter = function () {
	let header = dom.getElement(__.oDefaults.selectorHeader),
		footer = dom.getElement(__.oDefaults.selectorFooter),
		footnote = dom.getElement(__.oDefaults.selectorFootnote);

	header.style.display = 'none';
	footer.style.display = 'none';
	footnote.style.paddingTop = '40px';
};

/**
 * is header and footer currently hidden
 * @returns {void}
 */
exports.isHiddenHeaderFooter = function () {
	return __.isHideHeaderFooter() || __.isHideHeaderFooterPersistent();
};

exports.initialize = function () {
	__.initialize();
};

export { exports as hideHeaderFooter };
