import { appEvents } from 'core-application';
import { dom } from 'core-utils';

const __ = {},
	exports = {
		__: __,
	};

__.oDefaults = {
	sSelectorModule: 'j-action-usabilla',
};

__.addEvents = function () {
	__.domDelegate.on(
		'click',
		'.j-action-usabilla',
		__.callExternalUsabillaModul,
	);
};

__.callExternalUsabillaModul = function (e) {
	if (!!e && !!e.preventDefault) {
		e.preventDefault();
	}

	try {
		if (typeof window.usabilla_live === 'function') {
			window.usabilla_live('click');
		} else {
			console.warn('no usabilla: window.usabilla_live not defined');

			__.eventEmitter.trigger(appEvents.ERROR);
		}
	} catch (error) {
		console.warn(
			'usabilla warn: something went wrong in window.usabilla_live: ',
			error,
		);
		__.eventEmitter.trigger(appEvents.ERROR);
	}
};

exports.initialize = function (eventEmitter) {
	__.eventEmitter = eventEmitter;
	__.domDelegate = dom.getEventDelegate('body');
	__.addEvents();
};

export { exports as usabilla };
