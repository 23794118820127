const exports = `
	<div class="nm-cookiepolicy" data-theme="dark" data-module="cookie-policy">
		<div class="nm-cookiepolicy-inner nm-text-list">
			<div>{{=it.text}}</div>
			<ul>
				<li>
					<a x-cq-linkchecker="skip" id="showPolicy" href="{{=it.linkUrl}}" class="nm-layerLink nm-link-basic">{{=it.linkText}}</a>
				</li>
				<li>
					<a x-cq-linkchecker="skip" style="text-decoration: none" id="continue" class="continue nm-link-basic">
						<span>{{=window.i18n['cookie-continue-label']}}</span>
						<svg class="nm-icon-arrow">
							<use xlink:href={{=SETUPS.get('coreci.url.assets')+"/icons/svg/arrow.svg#nm-icon-arrow"}}></use>
						</svg>
					</a>
				</li>
			</ul>
		</div>
	</div>
`;

export { exports as cookiePolicyTemplate };
