import { StorageTypes, stateRegistry } from 'microkernel';

/**
 * set dealer data in the store
 * @param {Object} state - the previous state
 * @param {Object} payload - payload, we want to use to update the store with a new state
 * @returns {Object} - new state
 */
const setDealer = function (state, payload) {
	return Object.assign({}, state, payload);
};

/**
 * @returns {Object} - new state
 */
const resetDealer = function () {
	return {};
};

class IphStore {
	static get NAME() {
		return 'dbadDealerStore';
	}

	static get ACTIONS() {
		return {
			RESET_DEALER: 'resetDealer',
			SET_DEALER: 'setDealer',
		};
	}

	/**
	 * is there an active store in local storage
	 * @returns {boolean} - whether there is an active store or not
	 */
	static activeStoreExistsInLocalStorage() {
		const serializedStore = window.localStorage.getItem('dbadDealerStore');

		if (serializedStore && serializedStore !== '') {
			const storeObject = JSON.parse(serializedStore);

			if (storeObject && storeObject.timestamp) {
				const isWithinLifetime =
					Date.now() - storeObject.timestamp < 24 * 60 * 60 * 1000;
				return isWithinLifetime;
			}
		}
		return false;
	}

	/**
	 * add the dealer store to the stateRegistry
	 * @param {Object} initialData - initial data for the store
	 * @returns {void}
	 */
	static addIphStore(initialData = {}) {
		stateRegistry.addStore(
			IphStore.NAME,
			initialData,
			{ resetDealer, setDealer },
			{ storageType: StorageTypes.LOCAL_STORAGE },
		);
	}
}

export {
	IphStore,
	setDealer as _testing_only_setDealer,
	resetDealer as _testing_only_resetDealer,
};
