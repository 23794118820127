/* global SETUPS */
import { appEvents as APPEVENTS } from 'core-application';

class InterstitialLayer {
	initialize() {
		const interstitialLayerLink = SETUPS.get('nemo.InterstitialLayerLink');
		const carline = this._getCurrentCarline();

		this._writeLayerHasBeenShown = this._writeLayerHasBeenShown.bind(this);

		if (!interstitialLayerLink || !carline) {
			return;
		}

		this.storageKey = this._getStorageKeyForCarline(carline);

		document.addEventListener(
			APPEVENTS.INTERSTITIAL_LAYER_CLOSE,
			this._writeLayerHasBeenShown,
		);

		this._processInterstitialLayer(interstitialLayerLink);
	}

	/**
	 * get the current carline
	 * @returns {string} - the current carline if set else empty string
	 */
	_getCurrentCarline() {
		let carlineMeta = document.querySelector('meta[name=carline]');

		if (!carlineMeta) {
			return '';
		}

		return carlineMeta.getAttribute('content');
	}

	/**
	 * process the interstitial layer display
	 * @param {string} interstitialLayerLink_ - the layer link
	 * @returns {void}
	 */
	_processInterstitialLayer(interstitialLayerLink_) {
		const layerHasAlreadyBeenShown = this._readLayerHasBeenShown();

		if (!layerHasAlreadyBeenShown) {
			this._openInterstitialLayer(interstitialLayerLink_);
		}
	}

	/**
	 * get the storage key for a particular carline
	 * @param {string} carline_ - the carline
	 * @returns {string} - the calculated storage key
	 */
	_getStorageKeyForCarline(carline_) {
		return 'audi-interstitial-layer-' + carline_;
	}

	/**
	 * read an item from session storage
	 * @returns {Object} item from session storage
	 */
	_readLayerHasBeenShown() {
		if (this.sessionStorageAlternative) {
			return this.sessionStorageAlternative[this.storageKey];
		} else {
			return sessionStorage.getItem(this.storageKey);
		}
	}

	/**
	 * open the defined interstitial layer
	 * @param {string} interstitialLayerLink_ - the link of the interstitial layer
	 * @returns {void}
	 */
	_openInterstitialLayer(interstitialLayerLink_) {
		const dataObject = {
			clickOnShaderClosesModal: false,
			dataset: {
				linkUrl: interstitialLayerLink_,
			},
			isLayerClosable: false,
			layerType: 'ModalLayerInterstitialElement',
		};

		document.dispatchEvent(
			new CustomEvent('MODAL_LAYER_OPEN', { detail: dataObject }),
		);
	}

	/**
	 * write a boolean to session storage
	 * @returns {void}
	 */
	_writeLayerHasBeenShown() {
		try {
			sessionStorage.setItem(this.storageKey, true);
		} catch (err) {
			this.sessionStorageAlternative = this.sessionStorageAlternative
				? this.sessionStorageAlternative
				: {};
			this.sessionStorageAlternative[this.storageKey] = true;
		}
	}
}

const interstitialLayer = new InterstitialLayer();
export { interstitialLayer, InterstitialLayer };
