/* global vee24, SETUPS */
import { IphStore } from './iph-store';
import { appEvents } from 'core-application';
import { iphCartTemplate } from './iph-cart-template';
import { stateRegistry } from 'microkernel';
import { template } from 'core-utils';

const __ = {},
	exports = {
		__: __,
	};

// private vars
__.oDefaults = {
	sClassIPHActive: 'nm-iph-active',
	sSelectorIPHCancelBtn: '.nm-iph-item .nm-icon-cancel',
	sSelectorIPHWrapper: '.nm-iph-wrapper',
};

/**
 * parse partner id from URL
 * @returns {string} partnerID - the parsed partner id
 */
exports.parsePartnerID = function () {
	let partnerID = false;
	if (
		window.location.search &&
		window.location.search.indexOf('partner_id=') > -1
	) {
		partnerID = window.location.search.split('partner_id=')[1];
	} else if (
		window.location.hash &&
		window.location.hash.indexOf('partner_id=') > -1
	) {
		partnerID = window.location.hash.split('partner_id=')[1];
	}
	if (partnerID) {
		partnerID = partnerID.split('&')[0];
	}
	return partnerID;
};

/**
 * add the partner id to a href
 * @param {string} origHref - the original href
 * @returns {sring} - patched href
 */
exports.addPartnerId = function (origHref) {
	let href = origHref;
	if (href.indexOf('partner=') === -1) {
		if (href.indexOf('?') > -1) {
			href += '&partner=' + __.dealerData.kvpsid;
		} else {
			href += '?partner=' + __.dealerData.kvpsid;
		}
	}
	return href;
};

/**
 * update nwb string in href (NWB is for neuwagenboerse)
 * @param {string} href - the original href
 * @returns {string} the expanded href
 */
exports.updateNWB = function (href) {
	if (href.search(/(a)+[a-z]*(nwbp)+/g) === -1) {
		return href.replace(/(a)+[a-z]*(nwb)+/g, function myFunction(x) {
			return x + 'p';
		});
	}
	return href;
};

/**
 * are we currently in IPH mode
 * @returns {boolean} - true, in case we are in IPH mode
 */
exports.inIPHMode = function () {
	return __.dealerData && __.dealerData.kvpsid;
};

/**
 * update dealer links:
 * - add partner parameter
 * - replace aXXnwb with aXXnwbp
 * @returns {void}
 */
__.updateDealerLinks = function () {
	let i, len, href, localVtpLink;
	const vtpLinks = __.renderContext.querySelectorAll('.nm-j-vtp-link');

	if (vtpLinks && vtpLinks.length > 0) {
		for (i = 0, len = vtpLinks.length; i < len; i++) {
			localVtpLink = vtpLinks[i];
			href = localVtpLink.getAttribute('href');
			href = exports.addPartnerId(href);
			localVtpLink.setAttribute('href', exports.updateNWB(href));
		}
	}
};

/**
 * Send CRM data using the Vee24 JavaScript API
 * @param {string} dealerBNR_ - Dealer Name to Submit
 * @param {string} dealerName_ - Dealer Name to Submit
 * @returns {void}
 */
__.sendCustomerDataToVeeAPI = function (dealerBNR_, dealerName_) {
	const POA_Data = [];
	if (typeof vee24 !== 'undefined') {
		POA_Data.push(new vee24.crm.Data('BNR', dealerBNR_));
		POA_Data.push(new vee24.crm.Data('Audipartner', dealerName_));
		vee24.crm.submit('Customer Information', POA_Data);
	}
};

/**
 * get the dealers phone label
 * @return {string} - the phone label
 */
__.getDealerPhoneLabel = function () {
	const i18nKey = 'basket.iph.phonelabel';
	return window.i18n && window.i18n[i18nKey] ? window.i18n[i18nKey] : 'Tel.';
};

/**
 * disable IPH basket
 * @param {Event|null} event - click Event
 * @returns {void}
 */
__.disableIPHBasket = function (event) {
	stateRegistry.triggerAction(IphStore.NAME, IphStore.ACTIONS.RESET_DEALER);

	if (event) {
		this.removeEventListener('click', __.disableIPHBasket);
	}
};

/**
 * provide search URL for given partnerID
 * @param {string} partnerID - the partnerID to search for
 * @returns {string} search URL to retrieve dealer data
 */
__.getDealerSearchUrl = function (partnerID) {
	const clientID = SETUPS.get('iph.clientid')
		? SETUPS.get('iph.clientid')
		: 'e2aaa0fa0c';
	const dealersearchURL = SETUPS.get('iph.dealersearchurl')
		? SETUPS.get('iph.dealersearchurl')
		: 'dealersearch.audi.com/api/json/v2/audi-deu';
	return (
		'//' + dealersearchURL + '/id?q=' + partnerID + '&clientId=' + clientID
	);
};

/**
 * check whether dealer data are complete
 * @returns {boolean} - whether all fields exist
 */
__.areDealerDataComplete = function () {
	return (
		!!__.dealerData &&
		__.dealerData.kvpsid &&
		__.dealerData.kvpsSyncId &&
		__.dealerData.dealerName &&
		__.dealerData.dealerURL &&
		__.dealerData.dealerPhone
	);
};

/**
 * get partner info for given partner ID
 * @param {string} kvpsid_ - the partner ID to get infos for
 * @returns {Promise} - promise for json parsing
 */
__.loadPartnerInfo = async function (kvpsid_) {
	try {
		const url = __.getDealerSearchUrl(kvpsid_);
		const response = await fetch(url);
		const json = await response.json();
		return json;
	} catch (err) {
		console.info(
			`loadPartnerInfo failed ${err.message ? err.message : err}`,
		);
		throw err;
	}
};

/**
 * check whether partner id is active (via session or via URL parameter)
 * @returns {void}
 */
__.checkForPartnerID = function () {
	const partnerID = exports.parsePartnerID();

	if (partnerID) {
		__.loadPartnerInfo(partnerID).then(function (data) {
			if (
				!!data &&
				!!data.meta &&
				data.meta.returnCode === 'OK' &&
				data.partners &&
				data.partners.length > 0
			) {
				__.dealerData = {
					dealerName: data.partners[0].name,
					dealerPhone:
						data.partners[0].contactDetails.phone.international,
					dealerURL: data.partners[0].url,
					kvpsSyncId: data.partners[0].kvpsSyncId,
					kvpsid: partnerID,
				};
				IphStore.addIphStore(__.dealerData);
			} else {
				__.addEmptyStoreIfNecessary();
			}
		});
	} else {
		__.addEmptyStoreIfNecessary();
	}
};

/**
 * add an empty dealer store (only if not provided via local storage)
 * @returns {void}
 */
__.addEmptyStoreIfNecessary = function () {
	if (!IphStore.activeStoreExistsInLocalStorage()) {
		IphStore.addIphStore({});
	}
};

/**
 * callback for IPH store updates
 * @param {Object} state_ the new state
 * @returns {void}
 */
__.onIphStoreUpdate = function (state_) {
	__.dealerData = state_;

	if (__.areDealerDataComplete()) {
		__.renderIPH();
	} else if (__.dealerData.kvpsid) {
		__.loadPartnerInfo(__.dealerData.kvpsid).then(function (data) {
			if (
				!!data &&
				!!data.meta &&
				data.meta.returnCode === 'OK' &&
				data.partners &&
				data.partners.length > 0
			) {
				__.dealerData = {
					dealerName: data.partners[0].name,
					dealerPhone:
						data.partners[0].contactDetails.phone.international,
					dealerURL: data.partners[0].url,
					kvpsSyncId: data.partners[0].kvpsSyncId,
					kvpsid: __.dealerData.kvpsid,
				};
			}
			__.renderIPH();
		});
	} else {
		__.renderNoIPH();
	}
};

/**
 * apply whether IPH is active to the context
 * @returns {void}
 */
__.applyIphToContext = function () {
	if (__.dealerData && __.dealerData.kvpsid) {
		__.renderIPH();
	} else {
		__.renderNoIPH();
	}
};

/**
 * redner the IPH mode
 * @returns {void}
 */
__.renderIPH = function () {
	const wrapper = __.renderContext.querySelector(
		__.oDefaults.sSelectorIPHWrapper,
	);

	if (wrapper) {
		const templateData = {
			dealerBNR: __.dealerData.kvpsid,
			dealerName: __.dealerData.dealerName,
			dealerPhone: __.dealerData.dealerPhone,
			dealerPhoneLabel: __.getDealerPhoneLabel(),
			dealerURL: __.dealerData.dealerURL,
		};

		document
			.querySelector('body')
			.classList.add(__.oDefaults.sClassIPHActive);
		wrapper.innerHTML = template.render(iphCartTemplate, templateData);
		const cancelButton = __.renderContext.querySelector(
			__.oDefaults.sSelectorIPHCancelBtn,
		);
		__.sendCustomerDataToVeeAPI(
			__.dealerData.kvpsid,
			__.dealerData.dealerName,
		);

		if (cancelButton) {
			cancelButton.addEventListener('click', __.disableIPHBasket);
		}

		if (SETUPS.get('scope.iph.dealerlink') === true) {
			__.updateDealerLinks();
		}
	}
};

/**
 * render non-IPH mode in basket
 * @returns {void}
 */
__.renderNoIPH = function () {
	document
		.querySelector('body')
		.classList.remove(__.oDefaults.sClassIPHActive);
};

/**
 * callback for the page loaded event
 * @param {Object} eventData - the event data
 * @returns {void}
 */
__.onPageLoaded = function (eventData) {
	__.renderContext =
		eventData && eventData.domElement ? eventData.domElement : document;
	__.applyIphToContext();
};

exports.initialize = function (eventEmitter) {
	__.eventBus = eventEmitter;

	if (SETUPS.get('scope.iph') === '1') {
		__.renderContext = document;
		stateRegistry.subscribeToStore(IphStore.NAME, __.onIphStoreUpdate);
		__.eventBus.on(appEvents.PAGE_LOADED, __.onPageLoaded);
		__.checkForPartnerID();
	} else {
		IphStore.addIphStore();
	}
};

export { exports as iph };
