import { bounceLink } from './src/bounce-link';
import { cookiePolicy } from './src/cookie-policy';
import { cookiePolicyTemplate } from './src/cookie-policy-template';
import { crsApi } from './src/crs/crs-api';
import { entryScroll } from './src/entry-scroll';
import { error } from './src/error';
import { errorLogging } from './src/error-logging';
import { hideHeaderFooter } from './src/hideHeaderFooter';
import { interceptDebugMode } from './src/intercept-debug-mode';
import { interstitialLayer } from './src/interstitial-layer';
import { iph } from './src/iph';
import { iphCartTemplate } from './src/iph-cart-template';
import { menuFlyOut } from './src/menu-fly-out';
import { modelNavigationWrap } from './src/model-navigation-wrap';
import { patternMatching } from './src/pattern-matching/pattern-matching';
import { poa } from './src/poa';
import { scroll } from './src/scroll';
import { scrollStickyElements } from './src/scroll-sticky-elements';
import { signal } from 'core-application';
import { state } from './src/state';
import { usabilla } from './src/usabilla';

/**
 * modules to be initialized with event emitter
 */
const toBeInitialized = [
	bounceLink,
	entryScroll,
	error,
	hideHeaderFooter,
	interstitialLayer,
	iph,
	menuFlyOut,
	scrollStickyElements,
	usabilla,
];

/**
 * initialize modules and inject signal dependency
 */
const eventEmitter = signal.getEmitter();
toBeInitialized.forEach((module) => {
	try {
		module.initialize(eventEmitter);
	} catch (err) {
		console.error(err);
	}
});

export {
	bounceLink,
	cookiePolicy,
	cookiePolicyTemplate,
	crsApi,
	entryScroll,
	error,
	errorLogging,
	hideHeaderFooter,
	interstitialLayer,
	iph,
	iphCartTemplate,
	interceptDebugMode,
	menuFlyOut,
	modelNavigationWrap,
	patternMatching,
	poa,
	scroll,
	scrollStickyElements,
	state,
	usabilla,
};
