(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("core-application"), require("core-utils"), require("microkernel"));
	else if(typeof define === 'function' && define.amd)
		define("core-global", ["core-application", "core-utils", "microkernel"], factory);
	else if(typeof exports === 'object')
		exports["core-global"] = factory(require("core-application"), require("core-utils"), require("microkernel"));
	else
		root["core-global"] = factory(root["core-application"], root["core-utils"], root["microkernel"]);
})(self, (__WEBPACK_EXTERNAL_MODULE__799__, __WEBPACK_EXTERNAL_MODULE__39__, __WEBPACK_EXTERNAL_MODULE__214__) => {
return 