import { appEvents } from 'core-application';
import { dom } from 'core-utils';

const __ = {},
	exports = { __: __ };

/* eslint-disable sort-keys */
__.oDefaults = {
	sSelectorHeaderItem: '.nm-navigation-header-item',
	sSelectorOverlayOpen: '.nm-header-overlay-is-open',
	sSelectorFlyoutMenu:
		'.nm-navigation-sitemap-wrap > .nm-navigation-sitemap-wrap-link',
	sSelectorHeaderLink: '.nm-navigation-header-link',
	sSelectorHeaderDirectLink: 'nm-navigation-header-direct-link',
	sSelectorNavigationContainer: '.nm-navigation-header-detail-container',
	sSelectorNavigation: '.nm-navigation-header-detail-container > ul',
	sSelectorNavigationActiveLink:
		'.nm-header-overlay-is-open .nm-navigation-header-detail-container > ul > li.nm-header-detail-link-active',
	sSelectorNavigationLink:
		'.nm-navigation-header-detail-container > ul > li > a',
	sClassDetailLinkActive: 'nm-header-detail-link-active',
	sSelectorOverlay: '.nm-navigation-header-overlay',
	sClassOverlayOpen: 'nm-header-overlay-is-open',
	sSelectorOverlayContainer: '.nm-header-overlay-container',
	sSelectorDropdownVisibiltyCheck: '#nm-navigation-dropdown-menu',
	sSelectorAdditionalLogo: '.nm-has-additional-logo',
	sNumLogoHeightAdjustment: 30,
	sNumMenuBreakpoint: 899,
	sNumMenuBreakpointCarline: 999,
	sNumHoverTimeout: 300,
	sNumTriangleHeight: 460,
	sNumOverlayHeightOffset: 85,
	sNumOverlayHeightDetailOffset: 120,
};
/* eslint-enable sort-keys */

exports.open = function (item) {
	let link = dom.getElement('a', item),
		id,
		container,
		itemOverlay,
		detailOverlays;

	__.addBackGroundEventOnce();
	item.setAttribute('flyout-is-open', 'true');

	if (dom.isElement(link)) {
		id = link.getAttribute('data-ref-id');
	}

	container =
		__.oDefaults.sSelectorOverlayContainer +
		' ' +
		__.oDefaults.sSelectorOverlay +
		'[data-ref-id="' +
		id +
		'"]';
	itemOverlay = dom.getElement(container);
	__.setFlyoutHeight(itemOverlay);

	detailOverlays = dom.getElementsArray(
		'.nm-navigation-header-detail .nm-navigation-header-detail-overlay',
		itemOverlay,
	);
	detailOverlays.forEach(function (overlay) {
		overlay.style['transition-delay'] = '0s';
	});

	itemOverlay.classList.add(__.oDefaults.sClassOverlayOpen);
	__.eventBus.emit(appEvents.MENUFLYOUT_OPEN, { item: item });

	// Portal Navigation -> CSS Menu Schliessen
	__.closeDropdownMenu();
	item.classList.add(__.oDefaults.sClassOverlayOpen);
};

exports.close = function (item) {
	let link = dom.getElement('a', item),
		url,
		container,
		itemOverlay;

	if (dom.isElement(link)) {
		url = link.getAttribute('href');
	}
	container =
		__.oDefaults.sSelectorOverlayContainer +
		' ' +
		__.oDefaults.sSelectorOverlay +
		'[data-ref-url="' +
		url +
		'"]';
	itemOverlay = dom.getElementsArray(container);

	__.removeBackGroundEvent();
	item.setAttribute('flyout-is-open', 'false');

	itemOverlay.forEach(function (overlay) {
		if (overlay && overlay.getAttribute('data-height') !== undefined) {
			overlay.style.height = '0';
		}
		let overlayWrapper = dom.getElementsArray(
			'.nm-navigation-header-detail .nm-navigation-header-detail-overlay-wrap',
			overlay,
		);
		overlayWrapper.forEach(function (overlayWrap) {
			overlayWrap.style['transition-delay'] = '1s';
		});
		overlay.classList.remove(__.oDefaults.sClassOverlayOpen);
	});

	__.eventBus.emit(appEvents.MENUFLYOUT_CLOSE, { item: item });
	item.classList.remove(__.oDefaults.sClassOverlayOpen);
};

exports.closeAll = function () {
	const items = dom.getElementsArray(__.oDefaults.sSelectorHeaderItem);
	items.forEach(function (item) {
		exports.close(item);
	});
};

/*
 * checks, whether point (x0,y0) is inside the triangle spanned by (x1,y1) - (x3,y3)
 */
exports.isInsideTriangle = function (x0, y0, x1, y1, x2, y2, x3, y3) {
	// eslint-disable-line max-params

	const b0 = (x2 - x1) * (y3 - y1) - (x3 - x1) * (y2 - y1),
		b1 = ((x2 - x0) * (y3 - y0) - (x3 - x0) * (y2 - y0)) / b0,
		b2 = ((x3 - x0) * (y1 - y0) - (x1 - x0) * (y3 - y0)) / b0,
		b3 = ((x1 - x0) * (y2 - y0) - (x2 - x0) * (y1 - y0)) / b0,
		inside = b1 > 0 && b2 > 0 && b3 > 0;
	return inside;
};

__.itemIsOpen = function (item) {
	const attr = item.getAttribute('flyout-is-open');
	if (attr && attr === 'true') {
		return true;
	}
	return false;
};
__.handleMenuClick = function (e) {
	const link = this,
		item = link.parentNode;
	if (link.classList.contains(__.oDefaults.sSelectorHeaderDirectLink)) {
		return true;
	}
	e.preventDefault();

	if (!__.itemIsOpen(item)) {
		exports.closeAll();
		exports.open(item);
	} else {
		exports.close(item);
	}
};

__.handleCloseClick = function (e) {
	const overlay = dom.getElement(
		__.oDefaults.sSelectorHeaderItem + '.' + __.oDefaults.sClassOverlayOpen,
	);
	e.preventDefault();
	exports.close(overlay);
};

__.handleOverlayClick = function (e) {
	const is_outside = dom.isElementOutsideOfElementWithSelector(
		e.target,
		__.oDefaults.sSelectorOverlayOpen,
	);
	if (is_outside) {
		exports.closeAll();
	}
};

__.handleNavigationMouseEnter = function (e) {
	e.preventDefault();
	e.stopImmediatePropagation();
	__.activeItem = dom.getElement(__.oDefaults.sSelectorNavigationActiveLink);
	__.domEventDelegate.on('mousemove', __.handleNavigationMouseMove);
};

__.handleNavigationMouseLeave = function () {
	__.domEventDelegate.off('mousemove', __.handleNavigationMouseMove);
};

__.handleActiveItemChange = function (newActiveItem) {
	__.activeItem.classList.remove(__.oDefaults.sClassDetailLinkActive);
	__.activeItem = newActiveItem;
	__.activeItem.classList.add(__.oDefaults.sClassDetailLinkActive);
};

__.handleHoverTimeout = function () {
	if (
		dom.isElement(__.activeItemNominee) &&
		!(__.activeItemNominee.parentNode === __.activeItem)
	) {
		__.handleActiveItemChange(__.activeItemNominee.parentNode);
	}
};

__.handleNavigationMouseMove = function (e) {
	// eslint-disable-line max-statements
	__.activeItemNominee = dom.closest(
		e.target,
		__.oDefaults.sSelectorNavigationLink,
	);
	if (!__.activeItemNominee) {
		return;
	}

	const navigationSelector = dom.closest(
		e.target,
		__.oDefaults.sSelectorNavigation,
	);
	const navigationSelectorLink = dom.getElement(
		'a',
		navigationSelector.firstElementChild,
	);
	__.topBoundingRect = navigationSelectorLink.getBoundingClientRect();

	// limit to visible li area (the li-s currently use full horizontal space...)
	if (e.clientX < __.topBoundingRect.right) {
		__.x0 = e.clientX;
		__.y0 = e.clientY;

		if (!__.x1 || !__.y1) {
			__.x1 = __.x0;
			__.y1 = __.y0;
		}
		__.x2 = __.topBoundingRect.right;
		__.y2 = Math.max(0, __.topBoundingRect.top);
		__.x3 = __.x2;
		__.y3 = __.topBoundingRect.top + __.oDefaults.sNumTriangleHeight;

		if (__.activeItemNominee.parentNode === __.activeItem) {
			if (
				!exports.isInsideTriangle(
					__.x0,
					__.y0,
					__.x1,
					__.y1,
					__.x2,
					__.y2,
					__.x3,
					__.y3,
				)
			) {
				__.x1 = __.x0;
				__.y1 = __.y0;
			}
		} else {
			if (__.activeItem) {
				if (
					exports.isInsideTriangle(
						__.x0,
						__.y0,
						__.x1,
						__.y1,
						__.x2,
						__.y2,
						__.x3,
						__.y3,
					)
				) {
					clearTimeout(__.hoverTimeout);
					__.hoverTimeout = setTimeout(
						__.handleHoverTimeout,
						__.oDefaults.sNumHoverTimeout,
					);
				} else {
					__.handleActiveItemChange(__.activeItemNominee.parentNode);
				}
			}
		}
	}
};

__.handleFlyoutLinkClick = function (e) {
	let submenu;
	if (
		window.innerWidth > __.oDefaults.sNumMenuBreakpoint ||
		dom.getElement('.nm-state-is-responsive').length === 0
	) {
		return;
	}

	if (
		dom.isElement(e.target) &&
		e.target.classList.contains('nm-has-sub-nav')
	) {
		e.preventDefault();
		e.stopImmediatePropagation();

		submenu = dom.closest(e.target, '.nm-navigation-sitemap-wrap');
		if (dom.isElement(submenu)) {
			submenu.classList.toggle('nm-flyout-is-expanded');
		}
	}
};

__.addBackGroundEventOnce = function () {
	__.domEventDelegate.on('click', __.handleOverlayClick);
};

__.removeBackGroundEvent = function () {
	__.domEventDelegate.off('click', __.handleOverlayClick);
};

__.handleWindowResize = function () {
	__.setDataHeight();
	__.setFlyoutHeight(
		dom.getElement(
			'.nm-navigation-header-overlay.nm-header-overlay-is-open',
		),
	);
	__.setDetailFlyoutHeight();
};

__.addEvents = function addEvents() {
	window.addEventListener('resize', __.handleWindowResize);

	__.domEventDelegate.on(
		'click',
		__.oDefaults.sSelectorHeaderItem +
			' ' +
			__.oDefaults.sSelectorHeaderLink,
		__.handleMenuClick,
	);
	__.domEventDelegate.on(
		'click',
		__.oDefaults.sSelectorOverlay + ' .nm-icon-close',
		__.handleCloseClick,
	);
	__.domEventDelegate.on(
		'mouseover',
		__.oDefaults.sSelectorNavigation,
		__.handleNavigationMouseEnter,
	);
	__.domEventDelegate.on(
		'mouseout',
		__.oDefaults.sSelectorNavigation,
		__.handleNavigationMouseLeave,
	);
	__.domEventDelegate.on(
		'click',
		__.oDefaults.sSelectorFlyoutMenu,
		__.handleFlyoutLinkClick,
	);
};

/* set data-height-attribute for main navigation overlays */
__.setDataHeight = function () {
	const headerOverlays = dom.getElementsArray(
		'.nm-header-overlay-container .nm-navigation-header-overlay',
	);
	headerOverlays.forEach(function (item) {
		const itemContent = dom.getElement(
			__.oDefaults.sSelectorNavigationContainer,
			item,
		);
		const detailContent = dom.getElementsArray(
			'.nm-navigation-header-detail-overlay-content',
			itemContent,
		);
		let detailContentMaxHeight = 0;
		let overlayHeight =
			itemContent.clientHeight + __.oDefaults.sNumOverlayHeightOffset;

		if (detailContent.length) {
			detailContent.forEach(function (content) {
				detailContentMaxHeight =
					detailContentMaxHeight < content.clientHeight
						? content.clientHeight
						: detailContentMaxHeight;
			});
			detailContentMaxHeight +=
				__.oDefaults.sNumOverlayHeightDetailOffset;
			overlayHeight =
				overlayHeight < detailContentMaxHeight
					? detailContentMaxHeight
					: overlayHeight;
		}

		// adjust flag for double - row navigation
		if (dom.isElement(__.oDefaults.sSelectorAdditionalLogo)) {
			overlayHeight += __.oDefaults.sNumLogoHeightAdjustment;
		}
		// end adjust

		item.setAttribute('data-height', overlayHeight);
	});
};

__.setDetailFlyoutHeight = function () {
	// eslint-disable-line max-statements
	let activeDetailOverlay = dom.getElement(
			'.nm-header-overlay-container .nm-navigation-header-overlay.nm-header-overlay-is-open',
		),
		activeDetailItem,
		newOverlayHeight,
		closeIcon;

	if (!dom.isElement(activeDetailOverlay)) {
		return;
	}

	activeDetailItem = dom.getElement(
		__.oDefaults.sSelectorNavigationContainer +
			' .nm-navigation-header-detail.nm-header-detail-link-active .nm-navigation-header-detail-overlay ' +
			'.nm-navigation-header-detail-overlay-content',
		activeDetailOverlay,
	);
	if (!dom.isElement(activeDetailItem)) {
		return;
	}
	newOverlayHeight = activeDetailItem.clientHeight;

	// adjust flag for double - row navigation
	if (dom.isElement(__.oDefaults.sSelectorAdditionalLogo)) {
		newOverlayHeight += __.oDefaults.sNumLogoHeightAdjustment;
	}
	// end adjust

	if (
		dom.isElement(
			'.nm-navigation-header-detail-container-carline',
			activeDetailItem,
		) &&
		window.innerWidth < __.oDefaults.sNumMenuBreakpointCarline
	) {
		newOverlayHeight = 0;
		activeDetailItem.setAttribute('data-height', newOverlayHeight);
		closeIcon = dom.getElement('.nm-icon-close');
		if (dom.isElement(closeIcon)) {
			closeIcon.click();
		}
	} else {
		activeDetailItem.setAttribute('data-height', newOverlayHeight);
		__.setFlyoutHeight(activeDetailItem);
	}
};

__.setFlyoutHeight = function (itemOverlay) {
	let overlay = itemOverlay,
		headerOverlayOpen = dom.getElement(
			'.nm-navigation-header-overlay.nm-header-overlay-is-open',
		);
	if (
		!dom.isElement(overlay) &&
		dom.isElement(headerOverlayOpen) &&
		headerOverlayOpen.getAttribute('data-ref-id') === '0'
	) {
		overlay = dom.getElement(
			'.nm-navigation-header-overlay.nm-header-overlay-is-open',
		);
	}

	if (
		dom.isElement(overlay) &&
		overlay.getAttribute('data-height') !== undefined
	) {
		overlay.style.height = overlay.getAttribute('data-height') + 'px';
	}
};

__.closeDropdownMenu = function () {
	const menu = dom.getElement(__.oDefaults.sSelectorDropdownVisibiltyCheck);
	if (dom.isElement(menu)) {
		if (menu.checked) {
			menu.checked = false;
		}
	}
};

__.initialize = function () {
	__.addEvents();
	__.setDataHeight();
};

exports.initialize = function (eventEmitter) {
	__.eventBus = eventEmitter;
	__.domEventDelegate = dom.getEventDelegate('body');
	__.initialize();
};

export { exports as menuFlyOut };
