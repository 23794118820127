/* global SETUPS */
import { cookiePolicyTemplate } from './cookie-policy-template';
import { dom } from 'core-utils';
import { template } from 'core-utils';

const __ = {},
	exports = {
		__: __,
	};

__.checkCookiePolicy = function () {
	const header = dom.getElement('.nm-header');
	let optionsData;

	if (!dom.isElement(header)) {
		return;
	}

	// check if entry in localStorage was set
	if (
		SETUPS.get('cookie.policy.scope') &&
		!(localStorage.getItem('cookiepolicy') || __.localCookiePolicy)
	) {
		optionsData = {
			linkText: SETUPS.get('cookie.link.text'),
			linkUrl: SETUPS.get('cookie.link.url'),
			text: SETUPS.get('cookie.text'),
		};

		header.insertAdjacentHTML(
			'beforebegin',
			template.render(cookiePolicyTemplate, optionsData),
		);
	}
};

__.handleCookieAccepted = function () {
	const el = dom.getElement('.nm-cookiepolicy');
	try {
		localStorage.setItem('cookiepolicy', true);
	} catch (err) {
		__.localCookiePolicy = true;
	}
	if (dom.isElement(el)) {
		el.classList.add('nm-hidden');
	}
};

__.addEvents = function () {
	__.domEventDelegate.on('click', '.continue', __.handleCookieAccepted);
};

__.initialize = function () {
	if (!__.init) {
		__.addEvents();
	}
	__.init = true;
	__.checkCookiePolicy();
};

exports.initialize = function () {
	__.domEventDelegate = dom.getEventDelegate('body');
	__.initialize();
};

exports.initialize();

export { exports as cookiePolicy };
