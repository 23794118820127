/**
 * @description shows error layers. you can open a layer with:
 * if you leave out the error message a default one will be used
 * @namespace nemo.module.error
 * @depends
 */

import { appEvents } from 'core-application';
import { dom } from 'core-utils';
import { layerApi } from 'core-application';
import { layerErrorTemplate } from 'core-application';

/**
 * @exports configurator/conflict
 */
const // Private
	__ = {},
	// Public API
	exports = {
		__: __,
	};

__.oDefaults = {
	layerSelectorClose: '.nm-error-layer .nm-button-close',
	layerSelectorOK: '.nm-error-layer .nm-j-ok-btn',
};

/**
 * global Error Handler
 * @param {Object|array|string} error_ - can be either object with attributes message and headline
 * or an array [headline,message] or just a messagetext
 * @returns {void} nothing
 */
__.onError = function (error_) {
	exports.handleError(__.getErrorTexts(error_));
};

/**
 * generates error message and headline from diffrent kinds of error params
 * @param {Object|array|string} error_ - can be either object with attributes message and headline
 * or an array [headline,message] or just a messagetext
 * @returns {Object} textobject with message and headline
 */
__.getErrorTexts = function (error_) {
	const textObj = {
		errorHeadline: null,
		errorMessage: null,
	};
	// handle Error Array [headline,message]
	if (Array.isArray(error_)) {
		textObj.errorHeadline = error_[0];
		textObj.errorMessage =
			error_.length === 2 && !!error_[1] ? error_[1] : null;
		return textObj;
	}
	// handle Error Message-String
	if (typeof error_ === 'string') {
		textObj.errorMessage = error_;
		return textObj;
	}
	// handle Error Object with message and headline attribute
	if (!!error_ && (!!error_.message || !!error_.headline)) {
		textObj.errorMessage = error_.message;
		textObj.errorHeadline = error_.headline;
		return textObj;
	}
	return textObj;
};

/**
 * add event Listener
 * @returns {void}
 */
__.addEvents = function () {
	__.domEventDelegate.on(
		'click',
		__.oDefaults.layerSelectorClose,
		__.closeLayer,
	);
	__.domEventDelegate.on(
		'click',
		__.oDefaults.layerSelectorOK,
		__.closeLayer,
	);
};

/**
 * remove event Listener
 * @returns {void}
 */
__.removeEvents = function () {
	__.domEventDelegate.off(
		'click',
		__.oDefaults.layerSelectorClose,
		__.closeLayer,
	);
	__.domEventDelegate.off(
		'click',
		__.oDefaults.layerSelectorOK,
		__.closeLayer,
	);
};

/**
 * open Error Layer
 * @param {string} layerName - name of the template
 * @param {string} tplHtml - template content
 * @param {function} closeCallBackFn - close callback function
 * @returns {Promise} - a Promise (returning a layer instance on resolve)
 */
__.openLayer = function (layerName, tplHtml, closeCallBackFn) {
	// returns promise on open
	return new Promise(function (resolve, reject) {
		const layerPromise = layerApi.open(layerName, tplHtml, closeCallBackFn);
		layerPromise
			.then(function (layerInstance) {
				__.layerInstance = layerInstance;
				__.addEvents();
				resolve(__.layerInstance);
			})
			.catch(function (err) {
				reject(err);
			});
	});
};

/**
 * close layer by triggering the close method on the layer instance
 * @param {event} event - click event
 * @returns {Promise} closed
 */
__.closeLayer = function (event) {
	let promise, redirectURL;

	redirectURL = __.getRedirectURL();
	if (redirectURL !== null) {
		window.location.href = redirectURL;
	}
	__.cancelEvent(event);
	// Layer Instance returns promise on close
	promise = __.layerInstance.close();
	return promise.then(
		function () {
			__.layerInstance = null;
			__.onLayerClose();
		},
		function (err) {
			throw new TypeError('Error closing error Layer: ' + err.message);
		},
	);
};

/**
 * @returns {string} redirectURL
 */
__.getRedirectURL = function () {
	const body = dom.getElement('body');
	return dom.getDataAttribute(body, 'redirect-url');
};

/**
 * prevent default event behavior
 * @param {Object} event_ e.g. a click event
 * @return {Void}@returns {void}
 */
__.cancelEvent = function (event_) {
	if (!!event_ && typeof event_.preventDefault === 'function') {
		event_.preventDefault();
	}
};

/**
 * close CallBack (e.g. on shader click)
 * @returns {void}
 */
__.onLayerClose = function () {
	__.removeEvents();
};

/**
 * handle an error response
 * opens an error layer
 * @param {Object} error_ - the raw error object
 * calls layerApi.open
 * @returns {Promise} - TODO
 */
exports.handleError = function (error_) {
	let templateType, templateString, $messageEl, html, errorData;

	return new Promise(function (resolve, reject) {
		templateType = 'error-layer';
		errorData = error_;
		errorData.errorHeadline = errorData.errorHeadline
			? errorData.errorHeadline
			: window.i18n['error-layer.headline'];
		templateString = dom.renderTemplate(layerErrorTemplate, errorData);
		__.renderedElement =
			dom.createElementsFromHtmlString(templateString)[0];

		// set default error message if none set
		if (!errorData.errorMessage) {
			$messageEl = dom.getElement(
				'.nm-error-layer-message',
				__.renderedElement,
			);
			errorData.errorMessage = $messageEl.getAttribute(
				'data-defaultErrorMessage',
			);
			$messageEl.innerText = errorData.errorMessage;
		}
		html = __.renderedElement.outerHTML;
		__.openLayer(templateType, html, __.closeLayer).then(resolve, reject);
	});
};

exports.initialize = function (eventEmitter) {
	__.eventBus = eventEmitter;
	__.domEventDelegate = dom.getEventDelegate('body');
	__.eventBus.on(appEvents.ERROR, __.onError);
};

export { exports as error };
