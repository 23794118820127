import { dom } from 'core-utils';
import { error } from './error';

const __ = {},
	exports = {
		__: __,
	};

/**
 * default Values used several times
 * @member {Object} oDefaults
 * @example
 * //To call inner values:
 * __.oDefaults = { 'className' : '.my-name' };
 * console.log(__.oDefaults.className);
 */
__.oDefaults = {
	disableTrackJSLinkSelector: '.j-trackjs-optout',
};

/**
 * add DOM events
 *@returns {void} nothing
 */
__.addEvents = function () {
	const storage = __.getStorage();
	// read settings for trackJS Logging from local storage on init and disable if necessary
	if (storage && storage.getItem('trackJsLogging') === 'false') {
		__.disableTrackJSLogging(true);
	}
	// disable TrackJS logging by setting a persistent flag in the localStorage
	__.domDelegate.on(
		'click',
		__.oDefaults.disableTrackJSLinkSelector,
		__.disableTrackJSLogging,
	);

	window.addEventListener(
		'unhandledrejection',
		__.handleUnhandledRejectionErrors,
	);
};

/**
 * add ErrorHandler for unhandled Promise rejections
 * @param {Object} event - PromiseRejectionEvent
 * @returns {void} nothing
 */
__.handleUnhandledRejectionErrors = function (event) {
	console.warn(event.reason);
	if (window._trackJs && window._trackJs.track) {
		window._trackJs.track(event.reason); // eslint-disable-line
	}
};

/**
 * get an storage object
 * @returns {Object} localStorage
 */
__.getStorage = function () {
	return window.localStorage;
};

/**
 * disable trackJS Error logging for dataPrivacy reasons
 * sets a persistent flag in the local storage
 * @param {boolean} flag true if trackJS was deactivated before, else empty
 * @returns {void} nothing
 */
__.disableTrackJSLogging = function (flag) {
	let storage = __.getStorage(),
		status = storage.getItem('trackJsLogging') || 'true',
		message = window.i18n['nemo.ui.optout.message'],
		headline = window.i18n['nemo.ui.optout.headline'];

	if (flag !== true) {
		status = status === 'true' ? 'false' : 'true';
		if (status === 'true') {
			message = window.i18n['nemo.ui.optin.message'];
			headline = window.i18n['nemo.ui.optin.headline'];
		}
		error.handleError({
			errorHeadline: headline,
			errorMessage: message,
			errorSubheadline: '',
		});
	}

	if (window._trackJs && window._trackJs.onError) {
		window._trackJs.onError = function () {
			// eslint-disable-line
			return status !== 'false';
		};
	}

	if (storage) {
		storage.setItem('trackJsLogging', status);
	}
};

exports.initialize = function () {
	__.domDelegate = dom.getEventDelegate('body');
	__.addEvents();
};

exports.initialize();

export { exports as errorLogging };
