import { scroll } from 'core-application';

const __ = {},
	exports = {
		__: __,
	};

__.navFixed = false;

/**
 * handleNaviStateChanged - fixes the navigation depending on the scroll position
 * @param {scroll} data_ - scroll object
 * @returns {void} returns nothing
 */
__.handleNaviStateChanged = function (data_) {
	if (data_.where === scroll.WHERE.ABOVE) {
		document.body.classList.add('nm-nav-fixed');
		__.navFixed = true;
	} else if (__.navFixed) {
		document.body.classList.remove('nm-nav-fixed');
		__.navFixed = false;
	}
};

/**
 * addEvents - registers events
 * @returns {void} returns nothing
 */
__.addEvents = function () {
	/* navigation sticky scroll */
	scroll.register(
		'.nm-navigation-scroll-check',
		{
			eventName: 'navigation-changed',
		},
		__.handleNaviStateChanged,
	);
};

/**
 * initialize - initializes the module
 * @returns {void} returns nothing
 */
__.initialize = function () {
	__.addEvents();
	document.body.classList.add('nm-not-sticky');
};

exports.initialize = function (eventEmitter) {
	__.eventBus = eventEmitter;
	__.initialize();
};

export { exports as scrollStickyElements };
