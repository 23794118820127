const __ = {},
	exports = {
		__: __,
	};

/**
 * default Values used several times
 *
 * @member {Object} oDefaults
 * @example
 * //To call inner values:
 * __.oDefaults = { 'className' : '.my-name' };
 * console.log(__.oDefaults.className);
 */
__.oDefaults = {};

__.registered = {};

/**
 * extract url pattern params
 * @param {string} url_ - TODO
 * @returns {Array} of pattern
 */
__.extractUrlParams = function (url_) {
	let pattern = [];

	if (url_ && url_.indexOf('{{') > 0) {
		// eslint-disable-next-line no-useless-escape
		pattern = url_.match(/{{\s*(=)?[(\w|_)\.|:]+\s*}}/g).map(function (x) {
			// eslint-disable-line no-useless-escape
			return x.match(/(=)?[\w\.|:]+/)[0]; // eslint-disable-line no-useless-escape
		});
	}

	return pattern;
};

/**
 * get replaced url
 * @param {string} patternUrl_ - String
 * @param {Object} fallbackUrl_ - TODO
 * @returns {Promise} url - String wrapped in Promise
 */
exports.getReplacedUrl = function (patternUrl_, fallbackUrl_) {
	let urlParams,
		promiseArray = [];

	urlParams = __.extractUrlParams(patternUrl_);
	urlParams.forEach(function (pattern) {
		promiseArray.push(__.replaceUrl(pattern));
	});

	return Promise.all(promiseArray)
		.then(function (array) {
			return __.buildPatchedUrl(array, patternUrl_);
		})
		.catch(function (err) {
			console.warn(err);
			return Promise.resolve(fallbackUrl_);
		});
};

/**
 * build patched url
 * @param {Object} array_ - TODO
 * @param {string} patternUrl_ - String
 * @returns {Promise} - TODO
 */
__.buildPatchedUrl = function (array_, patternUrl_) {
	let url = patternUrl_,
		patchedValue,
		key;

	array_.forEach(function (data) {
		key = Object.keys(data);
		// URL encode value to avoid url conflicts with special chars
		patchedValue = encodeURIComponent(data[key]);
		url = url.replace('{{' + key[0] + '}}', patchedValue);
	});

	return Promise.resolve(url);
};

/**
 * replace url
 * @param {string} pattern_ - String
 * @returns {Promise} - TODO
 */
__.replaceUrl = function (pattern_) {
	let func,
		mandatory,
		patternKey = pattern_.replace(':mandatory', ''),
		returnObject;

	return new Promise(function (resolve, reject) {
		func = __.registered[patternKey];
		returnObject = {};
		mandatory = !!pattern_.match(/:mandatory/);

		// check if registered function exists and is a promise
		if (func && typeof func === 'function') {
			console.info(
				'pattern-matching.js --> replaceUrl() --> mandatory: ',
				pattern_,
			);

			func(patternKey)
				.then(function (data) {
					if (!data && mandatory) {
						reject();
					} else {
						returnObject[pattern_] = data;
						resolve(returnObject);
					}
				})
				.catch(function (err) {
					if (mandatory) {
						reject(err);
					} else {
						returnObject[pattern_] = '';
						resolve(returnObject);
					}
				});
		} else {
			console.warn('no matching module found for pattern: ' + pattern_);

			if (mandatory) {
				reject();
			} else {
				// check if registered function exists and is a promise
				returnObject[pattern_] = '';
				resolve(returnObject);
			}
		}
	});
};

/**
 * public register method
 * @param {string} pattern_ - matching pattern string
 * @param {function} promiseCallback_ - callback function returns promise
 * @returns {void}
 */
exports.register = function (pattern_, promiseCallback_) {
	if (__.registered[pattern_]) {
		console.warn('pattern ist already registered: ', pattern_);
	} else {
		__.registered[pattern_] = promiseCallback_;
	}
};

export { exports as patternMatching };
